import React, { useContext, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Accordion, { AccordionItem } from '../../../../components/bootstrap/Accordion';
import Button from '../../../../components/bootstrap/Button';
import { useMst } from '../../../../models';
import AlarmService from '../../../../services/AlarmService';
import moment from 'moment';
import { Base64 } from 'js-base64';
// // import 'moment/locale/ko';

const NotificationList = observer(() => {
	const { t } = useTranslation(['translation', 'menu']);
	const { user, company, notifiction } = useMst();
	const navigate = useNavigate();

	const _checkRoute = type => {
		switch (type) {
		  case 'REQUEST_APPROVAL':
			return 'AcceptList';
			break;
		  case 'REQUEST_SUCCESS':
			return 'RequestList';
			break;
		  case 'JOIN': // OK
			return 'StaffManage';
			break;
		  case 'MODIFY_REQUEST': // OK
			return 'RequestView';
			break;
		  case 'RESTDAY_REQUEST': // OK
			return 'RequestView';
			break;
		  case 'ABSENSE':
			return 'RequestView';
			break;
		  case '52HOUR_OVERED':
			return 'RequestView';
			break;
		  case 'LEAVE_COMPANY': // OK
			return 'StaffManage';
			break;
		  case 'JOIN_CONFIRM': // OK
			return 'Home';
			break;
		  case 'JOIN_REJECT': // OK
			return 'CompanyList';
			break;
		  case 'RESTDAY_REQUEST_CONFIRM': // OK
			return 'Rest';
			break;
		  case 'RESTDAY_REQUEST_REJECT': // OK
			return 'Rest';
			break;
		  case 'REQUEST_CONFIRM': // OK
			return 'WorkList';
			break;
		  case 'REQUEST_REJECT': // OK
			return 'WorkList';
			break;
		  case 'ATTEND_BEFORE_FIVE_MINUTE':
			return 'Home';
			break;
		  case 'ATTEND_TIME':
			return 'Home';
			break;
		  case '52HOUR_OVER':
			return 'Home';
			break;
		  default :
			return 'Home';
			break;
		}
	  };

	const _readAlarm = async (alarm, index) => {
		//console.log('READ ALARM > ', alarm.id, alarm.type);
		if (!alarm.read) {
		  await AlarmService.alarmRead({ids: [alarm.id]}).then(() => {
			  notifiction.getRefresh(company.get.id);
			// store.NotificationStore.readList(index);
			// store.NotificationStore.reMoveBadge();
			// props.navigation.navigate(_checkRoute(alarm.type));
		  });
		}
		//  else {
		//   props.navigation.navigate(_checkRoute(alarm.type));
		// }
	  };

	return (
		<>
			{notifiction?.get?.list?.map((notis) => {				
				let noti = {...notis};
				noti.data = JSON.parse(noti.data);
				return (
					<Button 
						key={noti.id}
						type='button'						
						// isDisable={noti.read}
						color={null}
						className={`w-100 py-3 text-start border-bottom border-1`}
						onClick={() => {
							// console.log('>>>>', noti)
							if(!noti.read)
								_readAlarm(noti, noti.id);

							if(noti?.data?.detailId) {
								const cryptoUrl = Base64.encode(`${company.get.id}_${noti?.data?.detailId}`);
								window.location.href = `/e-approval/document/${cryptoUrl}`;
							}
						}}
						rounded={0}									
					>
						<div className='d-flex justify-content-between'>
							<span className={`${noti.read ? 'text-light' : 'text-info'}`}>[{t((noti.data.title || noti.title), noti.data)}]</span>
							<span className={`fw-light ${noti.read ? 'text-light' : 'text-muted'}`}>{moment(noti.date).format('LLL')}</span>
						</div>
						<div className={`ms-2 mt-2 fw-normal ${noti.read ? 'text-light' : ''}`}>
							{t((noti.data.message || noti.message), noti.data)}
						</div>
					</Button>
				);
			})}

			{notifiction?.get?.list?.length === 0 && (
				<div className='lead text-center py-5'>{t('요청된 알림이 없습니다')}</div>
			)}
		</>
	);
});
export default NotificationList;