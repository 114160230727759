import React, { Children, cloneElement, createRef, forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { randomColor } from '../helpers/helpers';
import Popovers from './bootstrap/Popovers';
import useDarkMode from '../hooks/useDarkMode';
// import human from '../assets/img/human.svg';

export const AvatarGroup = ({ className, children, size }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<div className={classNames('avatar-group', className)}>
			<div className='avatar-container'>
				{Children.map(children, (child, index) =>
					index < 3
						? cloneElement(child, {
								borderColor: darkModeStatus ? 'dark' : 'white',
								border: 2,
								color: child.props.color || randomColor(),
								size,
						  })
						: null,
				)}
			</div>
			{children.length > 3 && (
				<Popovers
					desc={(
						<div className='d-flex flex-row'>
						{Children.map(children, (child, index) =>
						index >= 3 ? (
							<>
								{child.props.userName},&nbsp;
								{index % 3 === 0 ? <br /> : null}
							</>
						) : null)}
						</div>)}										
					trigger='hover'>
					<div className='avatar-more' style={{ width: size, height: size }}>
						+{children.length - 3}
					</div>
				</Popovers>
			)}
		</div>
	);
};
AvatarGroup.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	size: PropTypes.number,
};
AvatarGroup.defaultProps = {
	className: null,
	size: 32,
};

const Avatar = forwardRef(
	(
		{
			srcSet,
			src,
			className,
			size,
			rounded,
			shadow,
			color,
			border,
			borderColor,
			userName,
			isOnline, // Not used
			isReply, // Not used
			...props
		},
		ref,
	) => {
		const { darkModeStatus } = useDarkMode();	
		// const [thisSrc, setThisSrc] = useState(src);
		// const [res, setRes] = useState(null);
		const r = useRef(null);
		const r2 = useRef(null);

		// console.log(">>>", src)

		useEffect(() => {
			const img = new Image();			
			img.src = src;
			img.onload = () => {
				// console.log('load img', src, r.current);
				if(r.current) {
					r.current.style.display = 'inline-flex';
					r.current.src = src;
				}
				if(r2.current)
					r2.current.style.display = 'none';
				// setRes('image');
				// setThisSrc(src);
			};     
			img.onerror = () => {
				if(r?.current && r2?.current) {
					r.current.style.display = 'none';
					r2.current.style.display = 'inline-flex';
				}
				// console.log('error img', src);
				// setThisSrc(null);
			}    
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [src, userName])

		// useEffect(() => {
		// 	// console.log('avatar img ', thisSrc);
		// 	const _Inner = thisSrc !== null ? (
		// 		<img
		// 			ref={r}
		// 			className={classNames(
		// 				'avatar',
		// 				{
		// 					[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
		// 					'rounded-0': rounded === 0 || rounded === '0',
		// 					[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
		// 					border: !!border,
		// 					[`border-${border}`]: !!border,
		// 					[`border-${borderColor}`]: borderColor,
		// 				},
		// 				`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
		// 				className
		// 			)}
		// 			srcSet={thisSrc}
		// 			src={thisSrc}
		// 			alt='Avatar'
		// 			width={size}
		// 			height={size}
		// 			// eslint-disable-next-line react/jsx-props-no-spreading
		// 			{...props}
		// 		/>
		// 	) : (
		// 		<div
		// 			ref={r}
		// 			className={classNames(
		// 				'avatar',
		// 				{
		// 					[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
		// 					'rounded-0': rounded === 0 || rounded === '0',
		// 					[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
		// 					border: !!border,
		// 					[`border-${border}`]: !!border,
		// 					[`border-${borderColor}`]: borderColor,
		// 				},
		// 				`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
		// 				className
		// 			)}
		// 			style={{
		// 				display: 'inline-flex',
		// 				alignItems: 'center',
		// 				justifyContent: 'center',
		// 				overflow: 'hidden',
		// 				borderRadius: size,
		// 				backgroundColor: '#E7E7E7',
		// 				width: size,
		// 				height: size,
		// 			}}>
		// 			{userName?.substr(0, 1) || ''}
		// 		</div>
		// 	);
		// 	// if(userName) {
		// 	// 	setRes(
		// 	// 		<Popovers desc={userName} trigger='hover'>
		// 	// 			{_Inner}
		// 	// 		</Popovers>					
		// 	// 	)
		// 	// } else {
		// 	// 	setRes(_Inner);
		// 	// }
		// 	setRes(_Inner);
		// 	// if (userName) {
		// 	// 	return (
		// 	// 		<Popovers desc={userName} trigger='hover'>
		// 	// 			{_Inner}
		// 	// 		</Popovers>
		// 	// 	);
		// 	// }

		// 	// eslint-disable-next-line react-hooks/exhaustive-deps
		// }, [thisSrc]);
		

		return (			
			<>
				{/* <div className='d-flex justify-content-center align-items-center'> */}
				<img
					ref={r}
					className={classNames(
						'avatar',
						{
							[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
							'rounded-0': rounded === 0 || rounded === '0',
							[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
							border: !!border,
							[`border-${border}`]: !!border,
							[`border-${borderColor}`]: borderColor,
						},
						`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
						className
					)}
					srcSet={src}
					src={src}
					alt='Avatar'
					style={{display: 'none'}}
					width={size}
					height={size}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
				/>
				<div
					ref={r2}
					className={classNames(
						'avatar',
						{
							[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
							'rounded-0': rounded === 0 || rounded === '0',
							[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
							border: !!border,
							[`border-${border}`]: !!border,
							[`border-${borderColor}`]: borderColor,
						},
						`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
						className
					)}
					style={{
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						overflow: 'hidden',
						borderRadius: size,
						backgroundColor: '#E7E7E7',
						width: size,
						height: size,
					}}>
					{userName?.substr(0, 1) || ''}
				</div>
			</>
			/* </div> */
		);

		// console.log(src)
		// img.src = src;
		// img.onload = () => {
		// 	return (<>asdasd</>)
		// 	// console.log('img>>>', img.width, img.height, innerRef)
		// 	// innerRef.current.html = <div>asdasd</div>
		// 	// const _Inner = (
		// 	// 	<img
		// 	// 		ref={ref}
		// 	// 		className={classNames(
		// 	// 			'avatar',
		// 	// 			{
		// 	// 				[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
		// 	// 				'rounded-0': rounded === 0 || rounded === '0',
		// 	// 				[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
		// 	// 				border: !!border,
		// 	// 				[`border-${border}`]: !!border,
		// 	// 				[`border-${borderColor}`]: borderColor,
		// 	// 			},
		// 	// 			`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
		// 	// 			className
		// 	// 		)}
		// 	// 		srcSet={imgSrc}
		// 	// 		src={imgSrc}
		// 	// 		alt='Avatar'
		// 	// 		width={size}
		// 	// 		height={size}
		// 	// 		// onError={(e) => {
		// 	// 		// 	console.log("AVATAR > ", e);
		// 	// 		// 	 ref.current.src = human;
		// 	// 		// 	// e.target.srcSet = human;
		// 	// 		// 	// e.target.src = 'https://cdn.vectorstock.com/i/preview-1x/32/12/default-avatar-profile-icon-vector-39013212.jpg'
		// 	// 		// }}
		// 	// 		// eslint-disable-next-line react/jsx-props-no-spreading
		// 	// 		{...props}
		// 	// 	/>
		// 	// );

		// 	// if (userName) {
		// 	// 	return (
		// 	// 		<Popovers desc={userName} trigger='hover'>
		// 	// 			{_Inner}
		// 	// 		</Popovers>
		// 	// 	);
		// 	// }
		// 	// return _Inner;
		// };     
		// img.onerror = () => {
		// 	return (<>222222222</>)
		// }    
		// img.onerror = () => {
		// 	const _Inner = (
		// 		<div
		// 			ref={ref}
		// 			className={classNames(
		// 				'avatar',
		// 				{
		// 					[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
		// 					'rounded-0': rounded === 0 || rounded === '0',
		// 					[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
		// 					border: !!border,
		// 					[`border-${border}`]: !!border,
		// 					[`border-${borderColor}`]: borderColor,
		// 				},
		// 				`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
		// 				className
		// 			)}
		// 			style={{
		// 				display: 'inline-flex',
		// 				alignItems: 'center',
		// 				justifyContent: 'center',
		// 				overflow: 'hidden',
		// 				borderRadius: size,
		// 				backgroundColor: '#E7E7E7',
		// 				width: size,
		// 				height: size,
		// 			}}>
		// 			{userName.substr(0, 1) || ''}
		// 		</div>
		// 	);
					
		// 	if (userName) {
		// 		return (
		// 			<Popovers desc={userName} trigger='hover'>
		// 				{_Inner}
		// 			</Popovers>
		// 		);
		// 	}
		// 	return _Inner;
		// } 
		// const _Inner = (
		// 	<div
		// 		ref={innerRef}
		// 		className={classNames(
		// 			'avatar',
		// 			{
		// 				[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
		// 				'rounded-0': rounded === 0 || rounded === '0',
		// 				[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
		// 				border: !!border,
		// 				[`border-${border}`]: !!border,
		// 				[`border-${borderColor}`]: borderColor,
		// 			},
		// 			`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
		// 			className
		// 		)}
		// 		style={{
		// 			display: 'inline-flex',
		// 			alignItems: 'center',
		// 			justifyContent: 'center',
		// 			overflow: 'hidden',
		// 			borderRadius: size,
		// 			backgroundColor: '#E7E7E7',
		// 			width: size,
		// 			height: size,
		// 		}}>
		// 		{userName.substr(0, 1) || ''}
		// 	</div>
		// );
		
		// if (userName) {
		// 	return (
		// 		<Popovers desc={userName} trigger='hover'>
		// 			{_Inner}
		// 		</Popovers>
		// 	);
		// }
		// return _Inner;
	}
);
Avatar.displayName = "Avatar";
Avatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
	className: PropTypes.string,
	size: PropTypes.number,
	rounded: PropTypes.oneOf([
		'default',
		0,
		1,
		2,
		3,
		'bottom',
		'top',
		'circle',
		'end',
		'start',
		'pill',
	]),
	color: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
		'brand',
		'brand-two',
		'storybook',
	]),
	shadow: PropTypes.oneOf([null, 'none', 'sm', 'default', 'lg']),
	border: PropTypes.oneOf([null, 0, 1, 2, 3, 4, 5]),
	borderColor: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
		'brand',
		'brand-two',
		'storybook',
		'white',
	]),
	userName: PropTypes.string,
	isOnline: PropTypes.bool,
	isReply: PropTypes.bool,
};
Avatar.defaultProps = {
	srcSet: null,
	className: null,
	size: 128,
	rounded: 'circle',
	color: 'primary',
	shadow: null,
	border: null,
	borderColor: null,
	userName: null,
	isOnline: false,
	isReply: false,
};

export default Avatar;
