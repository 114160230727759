import { types } from 'mobx-state-tree';
import moment from 'moment';
import { extraMenu } from '../menu';
import CompanyService from '../services/CompanyService';

// 개인 연차 정보
const restInfo = types
    .model({
        extraRestDay: types.optional(types.number, 0),
		joinDate: types.optional(types.string, ''),
		joinYear: types.optional(types.number, 0),
		lastdate: types.optional(types.string, ''),
		lastday: types.optional(types.number, 0),
		reqRest: types.optional(types.number, 0),
		rest: types.optional(types.number, 0),
		restM: types.optional(types.number, 0),
		//restTable: types.optional(types.array, []),
		restY: types.optional(types.number, 0),
		resttype: types.optional(types.string, ''),
		resttype2: types.optional(types.number, 0),
		susRest: types.optional(types.number, 0),
		userest: types.optional(types.number, 0),
    });

// 개인 조직 정보
const departInfo = types
	.model({
		departId: types.optional(types.number, 0),
		departName: types.optional(types.string, ''),		
		positionName: types.optional(types.string, ''),
		head: types.optional(types.boolean, false),
		main: types.optional(types.boolean, false),
	})



export const Company = types
	.model('Company', {
		id: types.optional(types.number, 0),
		name: types.maybe(types.string),
		tel: types.maybeNull(types.string),
		logo: types.maybeNull(types.string),
		address: types.maybe(types.string),
		addressDetail: types.maybeNull(types.string),
		createdAt: types.maybe(types.string),
		udpatedAt: types.maybe(types.string),
		isAdmin: types.maybeNull(types.boolean),
		isDoc: types.maybeNull(types.boolean),
		isSetting: types.maybeNull(types.boolean),
		ownerUserId: types.maybeNull(types.number),
		companyPeriod: types.maybeNull(types.array(types.string)),
		restType: types.maybeNull(types.boolean),
		restDate: types.maybeNull(types.string),
		securityDevice: types.maybeNull(types.boolean),
		companyAnniversary: types.maybeNull(types.string),
		
		// 회사 휴가 옵션
		restOptionMonth: types.maybeNull(types.string),
		restDestoryMonth: types.maybeNull(types.boolean),
		restGraceMonth: types.maybeNull(types.integer),

		restOptionYear: types.maybeNull(types.integer),
		restRoundYear: types.maybeNull(types.string),
		restDestoryYear: types.maybeNull(types.boolean),
		restGraceYear: types.maybeNull(types.integer),
		
		restUseUnit: types.maybeNull(types.string),
		restPromote: types.maybeNull(types.boolean),

		restInfo: types.maybe(restInfo),
		departInfos: types.optional(types.array(departInfo), []),	

		nickName: types.maybe(types.string),

		userRank: types.maybe(types.string),
		userDepartment: types.maybe(types.string),
		approvalSelf: types.maybeNull(types.boolean),

	})
	.views((self) => ({
		get get() {
			return { ...self };
		},
	}))
	.actions((self) => ({
		async selectCompany(id) {
			//console.log('select > ', id)
			const response = await CompanyService.selectCompany(id);
			// console.log("selectCompany >> ", response)
			if(response.data?.id) {
				// console.log(">>>", response.data);
				self.setData(response.data);
				self.setIsAdmin(response.data.isAdmin);		
				self.setDepartInfo(response.data.departments);

			} else {				
				window.location.replace(extraMenu.changeCompany.path);
			}
		},
		async getDepartInfo() {
			try { 
				const response = await CompanyService.selectCompany(self.id);
				self.setDepartInfo(response.data.departments)
			} catch (err) {
				//
			}
		},
		async setRestInfo(data) {			
			self.restInfo = data
		},
		async setDepartInfo(data) {
			self.departInfos = data
		},		
		async setData(data) {
			self = Object.assign(self, data);
		},
		async setIsAdmin(data) {
			self.isDoc = data;			
		},
		async setCompanyPeriod(data) {
			self.companyPeriod = data;
		},
		async logout() {
			self.id = 0;
			self.name = '';
			self.tel = '';
			self.logo = '';
			self.address = '';
			self.addressDetail = '';
			self.createdAt = '';
			self.udpatedAt = '';
			self.isAdmin = false;
			self.isSetting = true;
			self.ownerUserId = 0;
			self.companyPeriod = ['', ''];
			self.securityDevice = false;

			self.restOptionMonth = '';
			self.restDestoryMonth = true;
			self.restGraceMonth = 0;
			self.restOptionYear = 0;
			self.restRoundYear = 'ROUND';
			self.restDestoryYear = true;
			self.restGraceYear = 0;
			self.restUseUnit = 'HALF';
			self.restPromote = false;

			self.restInfo = {};
			self.departInfos = [];		
			
			self.nickName = '';
			self.userRank = '';
			self.userDepartment = '';

			self.approvalSelf = false;

		},
	}));
