import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import '@nosferatu500/react-sortable-tree/style.css'; // This only needs to be imported once in your app
import './i18n';
import App from './App';
import { ThemeContextProvider } from './contexts/themeContext';

/*
작업시 필독 사항
1. 알림 팝업 기능은 아래 함수 사용	
	showNotification(
		'title', // String, HTML or Component
		'content', // String, HTML or Component
		'message type (color)' // 'default' || 'info' || 'warning' || 'success' || 'danger',
	);	

2. 2군대 이상 사용되는 컴포넌트는 필수로 /src/components 에 넣을것
    해당 페이지에서만 사용되는 컴포넌트는 해당 페이지폴더 ./components/ 에 제작할것.
   2군대 이상 사용되는 CSS 도 컴포넌트와 같이 통일 폴더는 style

3. API 연동 URL Service 는 
   import services from "/src/services/Service" 로 불러서 사용 통일할것

4. Store 도 API Service와 통일..
   import stores from "/src/models/Stores" 로 호출

5. 구조는 아래 참조
	페이지
	/src/pages/1차뎁스/파일...
	/src/pages/1차뎁스/2차뎁스/파일...

6. 쿠키값은 
	localStorage.getItem()
	localStorage.setItem()

7. 번역기능 기본 넣어둘것.
	import { useTranslation } from 'react-i18next';

	함수블라블라 = () => {
		const { t } = useTranslation(['translation', 'menu']);
	}

	고정 텍스트에 {t("텍스트명")} 으로 출력
	
[ADD]	
	달력
	https://bestofreactjs.com/repo/Adphorus-react-date-range-react-date-time-picker
	npm install --save react-date-range

*/

// const registerServiceWorker = () => {
// 	if ('serviceWorker' in navigator) {
// 		navigator.serviceWorker
// 			.register('firebase-messaging-sw.js')
// 			.then(function (registration) {
// 				console.log(registration)
// 				return registration.scope;
// 			})
// 			.catch(function (err) {
// 				return err;
// 			});
// 	}
// };

window.progressbar = null;

const container = document.getElementById('root');
const root = createRoot(container);
 
root.render(
	<Router>
		<React.StrictMode>
			<ThemeContextProvider>
				<App />
			</ThemeContextProvider>
		</React.StrictMode>
	</Router>
);
//registerServiceWorker();