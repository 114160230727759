import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { observer } from 'mobx-react';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from './contexts/themeContext';
import Aside from './layout/Aside/Aside';
import Wrapper from './layout/Wrapper/Wrapper';
import Portal from './layout/Portal/Portal';
import { demoPages, extraMenu, layoutMenu, pawsswordFind } from './menu';
import Toasts, { Toast, ToastContainer } from './components/bootstrap/Toasts';
import useDarkMode from './hooks/useDarkMode';
import COLORS from './common/data/enumColors';
import steps, { styles } from './steps';
import { Provider, rootStore, useMst } from './models';
import firebaseInit from './firebaseInit';
import { getItem, setItem } from './lib/localstorage';
import { Configs } from './models/Configs';
import axios from 'axios';
import firebase from 'firebase/app';
import {
	ReactChannelIO,
	useChannelIOApi,
	useChannelIOEvent,
  } from 'react-channel-plugin';
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { getOS } from './helpers/helpers';
import Alert from './components/bootstrap/Alert';
import { isChrome, isEdge, isFirefox, isSafari, isIE } from 'react-device-detect';
import Button from './components/bootstrap/Button';


const App = observer(() => {
	
	const location = useLocation();
	const navigate = useNavigate();
	// const { user, company } = useMst();

	useEffect(()=>{
		/* console.log(`%c

		__/\\\\\\\\\\\\\\\\\\\\\\\\\\____/\\\\\\\\\\\\\\\\\\\\\\__/\\\\\\\\\\_____/\\\\\\_____/\\\\\\\\\\\\\\\\\\_____/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\_        
		_\\/\\\\\\/////////\\\\\\_\\/////\\\\\\///__\\/\\\\\\\\\\\\___\\/\\\\\\___/\\\\\\\\\\\\\\\\\\\\\\\\\\__\\///////\\\\\\/////__       
		 _\\/\\\\\\_______\\/\\\\\\_____\\/\\\\\\_____\\/\\\\\\/\\\\\\__\\/\\\\\\__/\\\\\\/////////\\\\\\_______\\/\\\\\\_______      
		  _\\/\\\\\\\\\\\\\\\\\\\\\\\\\\/______\\/\\\\\\_____\\/\\\\\\//\\\\\\_\\/\\\\\\_\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_______     
		   _\\/\\\\\\/////////________\\/\\\\\\_____\\/\\\\\\\\//\\\\\\\\/\\\\\\_\\/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\_______\\/\\\\\\_______    
			_\\/\\\\\\_________________\\/\\\\\\_____\\/\\\\\\_\\//\\\\\\/\\\\\\_\\/\\\\\\/////////\\\\\\_______\\/\\\\\\_______   
			 _\\/\\\\\\_________________\\/\\\\\\_____\\/\\\\\\__\\//\\\\\\\\\\\\_\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_______  
			  _\\/\\\\\\______________/\\\\\\\\\\\\\\\\\\\\\\_\\/\\\\\\___\\//\\\\\\\\\\_\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_______ 
			   _\\///______________\\///////////__\\///_____\\/////__\\///________\\///________\\///________		


		`, "color:black") */
		console.log(`%c
			   																							
		⠀⠀⠀⠀⠀⠀⠀⠀⠀⣰⣷⣦⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀															
		⠀⠀⠀⠀⠀⠀⠀⣀⣶⣿⣿⣿⣿⣿⣿⣷⣶⣶⣶⣦⣀⡀⠀⢀⣴⣇⠀⠀⠀⠀															
		⠀⠀⠀⠀⠀⢠⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠀⠀⠀⠀															
		⠀⠀⠀⠀⣰⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠀⠀⠀⠀														
		⠀⠀⠀⣴⣿⣿⣿⣿⠛⣿⣿⣿⣿⣿⣿⣿⣿⡿⣿⣿⣿⣿⣿⣿⣿⣿⣄⠀⠀⠀														
		⠀⠀⣾⣿⣿⣿⣿⣿⣶⣿⣯⣭⣬⣉⣽⣿⣿⣄⣼⣿⣿⣿⣿⣿⣿⣿⣿⣷⡀⠀													 	
		⠀⣸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡄														
		⢸⣿⣿⣿⣿⠟⠋⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠁⣿⣿⣿⣿⡿⠛⠉⠉⠉⠉⠁												  	  	
		⠘⠛⠛⠛\\\\\\\\\\\\\\\\\\\\\\\\\____/\\\\\\\\\\⠛⠛⠛⠃\\\\\\\\\\_____/\\\\\\_____/\\\\\\\\\\\\\\\\\\_____/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\_			
		_\\/\\\\\\/////////\\\\\\_\\/////\\\\\\///__\\/\\\\\\\\\\\\___\\/\\\\\\___/\\\\\\\\\\\\\\\\\\\\\\\\\\__\\///////\\\\\\/////__          
		 _\\/\\\\\\_______\\/\\\\\\_____\\/\\\\\\_____\\/\\\\\\/\\\\\\__\\/\\\\\\__/\\\\\\/////////\\\\\\_______\\/\\\\\\_______         
		  _\\/\\\\\\\\\\\\\\\\\\\\\\\\\\/______\\/\\\\\\_____\\/\\\\\\//\\\\\\_\\/\\\\\\_\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_______        
		   _\\/\\\\\\/////////________\\/\\\\\\_____\\/\\\\\\\\//\\\\\\\\/\\\\\\_\\/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\_______\\/\\\\\\_______       
			_\\/\\\\\\_________________\\/\\\\\\_____\\/\\\\\\_\\//\\\\\\/\\\\\\_\\/\\\\\\/////////\\\\\\_______\\/\\\\\\_______      
			 _\\/\\\\\\_________________\\/\\\\\\_____\\/\\\\\\__\\//\\\\\\\\\\\\_\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_______     
			  _\\/\\\\\\______________/\\\\\\\\\\\\\\\\\\\\\\_\\/\\\\\\___\\//\\\\\\\\\\_\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_______    
			   _\\///______________\\///////////__\\///_____\\/////__\\///________\\///________\\///________   
			   																							

`, 'background-color:#4d69fa;color:white;line-height:18px')

// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const [webToken, setwebToken] = useState(getItem('webToken'));
	//const webToken = getItem('webToken');

	let messaging = null;
	if (firebase.messaging.isSupported()) {
		messaging = firebaseInit.messaging();
	}

	const getFireBaseMessaging = async () => {
		messaging
		.requestPermission({
			alert: true,
			badge: true,
			sound: true,
			announcement: true,
		})
		.then(function () {
			// console.log('get Toekn?', messaging.getToken());
			return messaging.getToken(); //토큰을 받는 함수를 추가!
		})
		.then(function (token) {
			// console.log('SET TOKEN');
			setItem('webToken', token);
			setwebToken(token);
			Configs.setFcm(token).then(()=>{
				if(Configs.fcmCode && Configs.uuid && rootStore.user.me.id > 0) {
					Configs.sendDevice();
				}
			});
		})
		.catch(function (err) {
			//console.log('fcm에러 : ', err);
		});

	messaging.onTokenRefresh(function () {
		messaging
			.getToken()
			.then(function (refreshedToken) {
				// console.log('SET REFRESH TOKEN');
				setItem('webToken', refreshedToken);
				setwebToken(refreshedToken);
				Configs.setFcm(refreshedToken).then(()=>{
					if(Configs.fcmCode && Configs.uuid && rootStore.user.me.id > 0) {
						Configs.sendDevice();
					}
				});
				//console.log('Token refreshed.');
			})
			.catch(function (err) {
				//console.log('Unable to retrieve refreshed token ', err);
			});
	});
	}

	useEffect(() => { 
		//console.log('webToken', webToken, Configs.get);
	
		if (firebase.messaging.isSupported()) {
			if ((!webToken || webToken === '') && messaging) {
				// console.log('get Toekn');
				getFireBaseMessaging();
			}
		}
	
		//console.log(webToken)
		// if(webToken !== '') {
		// 	Configs.setFcm(webToken).then(()=>{
		// 		Configs.sendDevice();
		// 	})			
		// }
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [webToken]); 

	const [notification, setNotification] = useState('');
	useEffect(() => {		
		const requestNotification = () => {

			console.log("agent", window.navigator);

			if (!("Notification" in window)) {
				setNotification(null);
				console.log('not support notification');
			} else if (Notification.permission !== "granted") {
				Notification.requestPermission().then( async (permission) => {
					console.log('permission', permission);
					if (permission === "granted") {
						setNotification(true);
						if (firebase.messaging.isSupported()) {
							if (messaging) {
								getFireBaseMessaging();
							}
						}
						console.log('ok notification');
					} else {
						setNotification(false);
						console.log('no notification');
					}
				});
			} else {
				setNotification(true);
				console.log('ok notification');
			}
		}
		const getIpOs = () => {
			const os = getOS();
			Promise.all([
				getCurrentBrowserFingerPrint().then((fingerprint) => {						
					console.log('fingerprint', fingerprint)
					// setItem('uuid', fingerprint);
					Configs.setUuid(fingerprint);
				}),
				axios.get('//geolocation-db.com/json/').then( res => {
					// console.log('set public Ip', res.data)
					Configs.setPublicIp(res.data.IPv4);
					// console.log('res.data', res.data);
				}),
				Configs.setOs(os)
			]);			
		};	
		getIpOs();
		requestNotification();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus, lang } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path, extraMenu.changeCompany.path, pawsswordFind.PasswdReset.path];

	const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
	const forceUpdate = useCallback(() => updateState({}), []);

	useEffect(() => {
		// AUTH 확인
		// console.log('>>', location);
		if(withOutAsidePages.findIndex(v => '/' + v === location.pathname) === -1) {
			rootStore.user.authMe().then((data)	=> {
				if(rootStore.user.me.id === 0) {
					navigate('/'+demoPages.login.path);
				}
				if(!data.currentCompanyId) {
					navigate('/change-company');
				}
				// 각 필요한 값 기본 세팅.
				if(rootStore.company.get.id > 0) {
					// 공지사항
					// 알림개수
				}
			});			
		}
		// console.log('auth me', demoPages.login.path)		
		// console.log('rootStore', rootStore.user.me)
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	// useEffect(() => {
	// 	console.log('ROOT 강제 렌더링')
	// 	forceUpdate();
	// 	// navigate(0);
	// 	// window.location.reload();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [lang])

	return (
		<Provider value={rootStore}>
			<ThemeProvider theme={theme}>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<ReactChannelIO pluginKey={'caae8acb-b4c1-4a56-bb17-11251bad5b38'} hideChannelButtonOnBoot={true} language='ko' autoBoot autoBootTimeout={2000}>
						<TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
							{notification !== true && notification !== '' && (
								<>
								{/* {isChrome, isEdge, isFirefox, isSafari, isIE} */}
								<Alert rounded={0} color={'danger'} borderWidth={0} className='d-flex justify-content-center small fw-bold text-white'>
									알림 권한이 허용되지 않은 상태입니다.
									{/* {(isChrome || isEdge || isFirefox || isSafari) && 
										<Button size='sm' color='light' className='ms-3' onClick={()=>{
											isChrome ? window.location.href = 'chrome://settings/content' : 
											isFirefox ? window.location.href = 'about:preferences#privacy' :
											null
										}} >설정 바로가기</Button>
									} */}
								</Alert>
								</>
								)
							}
							<div
								ref={ref}
								className='app'
								style={{
									backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
									zIndex: fullScreenStatus && 1,
									overflow: fullScreenStatus && 'scroll',
								}}>
								<Routes>
									{withOutAsidePages.map((path) => (
										<Route key={path} path={path} />
									))}
									<Route path='*' element={<Aside />} />
								</Routes>
								<Wrapper />
							</div>
							<Portal id='portal-notification'>
								<ReactNotifications />
							</Portal>
						</TourProvider>
					</ReactChannelIO>
				</ToastProvider>
			</ThemeProvider>
		</Provider>
	);
});

export default App;
