import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import showNotification from '../../../components/extras/showNotification';
import { useMst } from '../../../models';
import CompanyService from '../../../services/CompanyService';
import { useTranslation } from 'react-i18next';

const Coupon = (props) => {
	const { company } = useMst();
	const { t } = useTranslation(['translation', 'menu']); //번역관련
	const [success_dlg, setsuccess_dlg] = useState(false);
	const [error_dlg, seterror_dlg] = useState(false);
	const [couponNumber, setCouponNumber] = useState('');
	const [dynamic_title, setdynamic_title] = useState('');
	const [dynamic_description, setdynamic_description] = useState('');
	const [columns, setColumns] = useState([]);
	const [couponList, setCouponList] = useState([]);
	const user = props.user;

	const paginationComponentOptions = {
		rowsPerPageText: '목록 개수',
		rangeSeparatorText: '-',
		selectAllRowsItem: true,
		selectAllRowsItemText: '개수',
	};
	const columns3 = [
		{ name: '코드번호', width: '120px', sortable: true, selector: (row) => row.code },
		{ name: '쿠폰명', width: '120px', sortable: true, selector: (row) => row.coupon.name },
		{
			name: '등록일',
			sortable: true,
			selector: (row) => moment(row.createdAt).format('YYYY-MM-DD'),
		},
	];

	const findCoupon = async () => {
		const res = await CompanyService.getUsedCopon(user.me.id).then((res) => {
			setCouponList(res.data);
		});
	};

	useEffect(() => {
		findCoupon();
		setColumns(columns3);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Card style={{ marginBottom: 0 }}>
				<CardHeader>
					<CardTitle>{t('쿠폰 사용내역')}</CardTitle>
				</CardHeader>
				<CardBody>
					<CardTitle>{t('사용한 쿠폰 목록')}</CardTitle>
					<div className='table-responsive'>
                        {columns3?.length > 0 &&
						<table className='table'>
							<thead>
								<tr>
									{columns3?.map((column, columnIndex) => {
										return <th key={columnIndex}>{t(column.name)}</th>;
									})}
								</tr>
							</thead>
							<tbody>
                            {couponList?.map((row)=> {
                                return (
                                    <tr key={row.id}>
                                        <td>{row.code}</td>
                                        <td>{row.coupon.name}</td>
                                        <td>{moment(row.createdAt).format('L')}</td>
                                    </tr>
                                )
                            })}                            
                            </tbody>
						</table>
                        }
                        {couponList?.length === 0 && <div className='lead text-center py-2'>{t('사용 내역이 없습니다')}</div>}
					</div>
					<Input
						className='form-control'
						type='search'
						placeholder={t('쿠폰코드입력')}
						onChange={(e) => {
							setCouponNumber(e.target.value);
						}}
					/>
				</CardBody>
				<CardFooter>
					<CardFooterLeft>
						<Button
							type='button'
							color='danger'
							onClick={(e) => {
								props.setIsModalOpen(false);
							}}>
							{t('닫기')}
						</Button>
					</CardFooterLeft>
					<CardFooterRight>
						<Button
							type='button'
							color='success'
							onClick={async () => {
								const couponCode = {
									code: couponNumber,
								};
								if (couponNumber.length > 5) {
									await CompanyService.usecoupon(couponCode).then(async (res) => {
										//console.log(res);
										if (res.message) {
											showNotification(
												'',
												t('유효하지 않은 쿠폰번호입니다'),
												'danger',
											);
										} else {
											Promise.all([
												showNotification(
													'',
													t('쿠폰을 사용하였습니다'),
													'success',
												),
                                                company.selectCompany(company.get.id),
                                                findCoupon(),
												//props.setIsModalOpen(false),
											]);
										}
									});
								} else {
									showNotification('', t('유효하지 않은 쿠폰번호입니다'), 'danger');
								}
							}}>
							{t('쿠폰 사용')}
						</Button>
					</CardFooterRight>
				</CardFooter>
			</Card>
		</>
	);
};

export default Coupon;
