import { types } from 'mobx-state-tree';
import { rootStore } from '.';
import { getItem, setItem } from '../lib/localstorage';

const NavTabMenu = types.model({
	id: types.optional(types.string, ''),
	name: types.optional(types.string, ''),
	path: types.optional(types.string, ''),
	isActive: types.optional(types.boolean, false),
});

export const NavTabMenus = types
	.model('NavTabMenus', {
		list: types.optional(types.array(NavTabMenu), []),
	})
	.views((self) => ({
		get get() {
			return { ...self };
		},
	}))
	.actions((self) => ({
		async setMenu(v) {
			self.list.push(v);
			// console.log('---', `navTabMenu_${rootStore.company.get.id}_${rootStore.company.get.isDoc ? 'admin':'user'}`)
			setItem(`navTabMenu_${rootStore.company.get.id}_${rootStore.company.get.isDoc ? 'admin':'user'}`, JSON.stringify(self.list));
		},
		async setMenus(v) {
			self.list = v;
			// console.log('---', `navTabMenu_${rootStore.company.get.id}_${rootStore.company.get.isDoc ? 'admin':'user'}`)
			setItem(`navTabMenu_${rootStore.company.get.id}_${rootStore.company.get.isDoc ? 'admin':'user'}`, JSON.stringify(self.list));
		},
        async resetMenu() {
            self.list = [];
			setItem(`navTabMenu_${rootStore.company.get.id}_${rootStore.company.get.isDoc ? 'admin':'user'}`, JSON.stringify(self.list));
        }
	}))
	// .create({
	// 	list: [],
	// });
